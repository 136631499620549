<template>
  <div class="page m-page">
    <h1 style="text-align: center; line-height: 240%; font-size: 16pt">
      <span style="font-family: 黑体">货物委托运输服务合作协议</span>
    </h1>
    <p style="font-size: 14pt">
      <span style="font-family: 黑体; font-weight: bold"
        >委托方：中山苗仓供应链管理有限公司（以下简称甲方）</span
      >
    </p>
    <p style="font-size: 14pt">
      <span style="font-family: 黑体; font-weight: bold">承运方：</span
      ><span style="font-family: 黑体; font-weight: bold; -aw-import: spaces"
        ></span
      ><span style="font-family: 黑体; font-weight: bold"
        >（以下简称乙方）</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >本协议是就承运方（以下简称“乙方”）与中山苗仓供应链管理有限公司（以下简称“甲方”）开发的苗仓“地被直采中心”平台（以下简称“平台”）交易的地被承运事宜所订立的协议。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >为了维护甲方平台正常的管理秩序，保障乙方在平台的良好体验和合法权益，乙方开始注册并使用平台的乙方服务，即表示乙方已充分阅读、理解并自愿接受本协议，同意受本协议各项条款的约束，用于更好的约束双方，有序开展交易。</span
      >
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第一条 协议成立及生效</span>
      </li>
    </ol>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >1.1乙方签订本协议前已充分阅读本协议的相关条款。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >1.2乙方签订本协议、缴纳诚信保证金并经平台审核相关乙方入驻资质，审核通过后平台五个工作日内以短信的形式通知乙方已开通帐号功能，同时本协议成立及生效。</span
      >
    </p>
    <ol start="2" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第二条 期限和终止</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>2.1协议期限</span>
      </li>
    </ol>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体">自签署之日起至协议解除之日止。</span>
    </p>
    <ol start="4" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>2.2 终止</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方有权申请解除本协议、终止合作，甲方审核通过后本协议终止</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方违反平台规则达到清退处罚的，则本协议终止。</span
      >
    </p>
    <ol start="5" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>2.3本协议终止前的事项</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >正在履行或已履行的运输订单任务已完成所有的业务服务</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >本协议终止乙方按本协议第五条中的相关细则条款办理退还保证金。</span
      >
    </p>
    <ol start="6" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>2.4协议终止后事项的处理 </span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >自本协议终止之日起，平台将关闭乙方在平台的账户权限，乙方将无法再通过该账户进行任何形式的操作及交易。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >本协议终止后，甲方平台有权保留商家的注册信息及交易行为记录等数据，但甲方没有为乙方保留这些数据的义务，亦不承担在协议终止后向乙方提供任何数据信息的义务，也不就协议终止向乙方或任何第三方承担责任，但法律另有规定的除外。</span
      >
    </p>
    <ol start="7" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第三条 合作内容</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>3.1合作内容</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >甲方利用平台网络技术和营销等优势，提供苗仓“地被直采中心”平台台的日常维护、技术支持，保证平台的正常运作。作为互联网经营者，甲方制定各项平台规则、为乙方开放司机端交易端口服务，派发运输订单给乙方委托乙方完成运输任务。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">乙方服务</span>
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >乙方按平台的要求，为平台派发的运输订单匹配相应的车辆完成从装货、运输、卸货等一条龙运输服务。</span
      >
    </p>
    <ol start="9" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第四条 物流公司合作商入驻</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>4.1 入驻要求</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方营业执照(三证合一）复印件加盖鲜章</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"> 乙方法人身份证正反面复印件。</span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方银行开户信息，优先中国农业银行、中国建设银行，其次中国工商银行、中国银行。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方的车辆型号{高栏车（4.2米、6.8米、9.6米）或视天气情况经双方协商后酌情安排其它类型车辆}。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方能将名下司机信息在苗仓“地被直采中心”平台司机端录入司机信息{驾驶证、行驶证、身份证、司机从业资格证、车辆道路运输证、车辆信息照片（包括车辆前面、后面、两侧页）}并确保信息真实有效。</span
      >
    </p>
    <ol start="11" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>4.2入驻流程</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">填写苗仓《物流承运商调查表》 </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">提交相关资质文件</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">签订本协议</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">缴纳诚信保证金</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">交招商专员录入系统</span>
    </p>
    <ol start="12" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>4.3入驻审核 </span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台审核，审核通过后5个工作日内平台以短信的形式通知物流承运商
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方收到审核通过短信后，进入小程序端，凭正确的手机号码及短信验证码勾选《用户服务协议》、《隐私协议》登录平台，将名下司机信息在苗仓“地被直采中心”司机小程序端录入，司机信息包括{行驶证、营运证、保险卡、驾驶证、身份证、从业资格证、车辆信息照片（包括车辆前面、后面、两侧面）}</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >在协议履行过程中，如相关信息（如营业执照、银行收款信息、司机信息）发生任何变更的物流合作商必须第一时间报平台重新审核。</span
      >
    </p>
    <ol start="13" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第五条：服务履约保证金</span>
      </li>
    </ol>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >服务履约保证金：人民币叁仟圆整（¥3000）</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >在乙方与甲方签订合同后一次性向甲方支付，甲方开具加盖财务章的《收据》作为凭证。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >履约保证金不计息，双方合作终止后，乙方无违规情况下，乙方凭甲方开具的《收据》，甲方全额退款；如乙方违规，则按违约扣除后的具体金额退还。</span
      >
    </p>
    <ol start="14" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第六条：运输费用、运输费用的结算方式、其它特珠原因</span>
      </li>
    </ol>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 黑体; font-weight: bold">6.1运输费用</span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        border: 1pt solid #000000;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            colspan="8"
            style="
              width: 472.45pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >苗仓“地被直采中心”平台地被运输报价单（含增值税普通发票）</span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 56.7pt">
          <td
            colspan="2"
            rowspan="2"
            style="
              width: 92.3pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市</span>
            </p>
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">区</span>
            </p>
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">尺寸：米</span>
            </p>
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">车型</span>
            </p>
          </td>
          <td
            style="
              width: 55.85pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >4.2m高栏车</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >6.8m高栏车</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >7.6m高栏车</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.6m（6轮高栏车）</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.6m（8轮高栏车）</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.6m（12轮高栏车）</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 55.85pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >4.15*2.25*2.3</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >6.75*2.3*2.4</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >7.55*2.3*2.5</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.55*2.3*2.5</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.55*2.3*2.5</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >9.55*2.3*2.5</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="5"
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">广州</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">黄埔区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">开发区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">从化区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">番禺区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="2"
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">佛山</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">南海</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">顺德</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="9"
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">东莞</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">莞城</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">郊区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">厚街</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">虎门</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">长安</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">樟木头</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">石碣</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">凤岗</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="6"
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">深圳</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">松岗</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">沙井</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">盐田</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">福永</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">龙岗</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">南山</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="2"
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">珠海</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">斗门</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">江门</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">惠州</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt">市区</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-indent: 10.5pt;
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 40.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt; text-indent: 21pt;">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 40.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="font-family: 宋体; font-size: 10.5pt;">
              <span>博罗</span>
            </p>
            <p style="height: 0; text-indent: 31.5pt;">
              <span>&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 55.95pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 49.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 47.7pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 57.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 51.2pt;
              border-style: solid;
              border-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 53.7pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p style="text-align: center; font-size: 10.5pt">
              <span style="-aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="8"
            style="
              width: 472.45pt;
              border-top-style: solid;
              border-top-width: 1pt;
              padding-right: 4.9pt;
              vertical-align: middle;
              background-color: #ffffff;
            "
          >
            <p
              style="
                text-align: justify;
                line-height: 20pt;
              "
            >
              <span style="font-family: 宋体; font-size: 10.5pt"
                >备注:1、横栏多点取货，超过（</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: spaces"
                >&nbsp;&nbsp;&nbsp; </span
              ><span style="font-family: 宋体; font-size: 10.5pt">）加收</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: spaces"
                >&nbsp;&nbsp;&nbsp; </span
              ><span style="font-family: 宋体; font-size: 10.5pt">钱/次</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: spaces"
                >&nbsp;&nbsp; </span
              ><span style="font-family: 宋体; font-size: 10.5pt"
                >2、三乡、板芙、民众、古镇加收</span
              ><span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: spaces"
                >&nbsp;&nbsp;&nbsp; </span
              ><span style="font-family: 宋体; font-size: 10.5pt">钱/次</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <ol start="15" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>6.2运输费用的结算方式</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >完成具体指派的订单任务，甲方的客户方确认收货无误后，平台自动支付该笔运费到乙方指定帐户。</span
      >
    </p>
    <ol start="16" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>6.3其它特殊原因</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >特殊原因需临时增加托运量，提前三个小时通知乙方，经双方协商同意后，由乙方安排车辆装货。</span
      >
    </p>
    <ol start="17" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第七条：订单管理</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>7.1订单管理</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>7.1.1订单提醒</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台在派发订单时，以平台“服务通知”的形式发送消息到乙方，</span
      >
    </p>
    <ol start="20" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>7.1.2订单接单确认</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方在收到平台派发的运力订单后，安排司机后在平台点击“确认接单”或“拒绝接单”，如乙方“确认接单”则进入“待取苗”状态</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >订单内容包含：订单编号、订单金额、平台跟单员信息、收苗地址、送达时间、收货人信息、取苗地址（多点取货的含多个点的地址、取苗线路）、相关苗圃联系方式、相关苗圃取苗明细。</span
      >
    </p>
    <ol start="21" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>7.1.3订单执行</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方在进入订单执行过程中，乙方司机到达相应取苗点需做如下工作</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >①需对应每个取苗点的取苗明细每个单品，按苗仓“地被直采中心”平台《承运商服务标准》的要求拍摄照片上传到平台；</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >②每个单点装货完成后司机按苗仓“地被直采中心”《承运商服务标准》的相关要求拍照上传到平台；</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >③扫苗二维码签收相应苗圃开具的《送货单》；</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >④所有单品照及装车完成后的整车照完成上传后，司机点击“发车确认”按钮，进入下一个取苗点重复以上工作内容；</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >⑤司机完成所有取苗点的取苗后，需将车身盖遮阴网，雨天盖苫布，并将整车拍照上传到平台，并联系平台跟单员点击“发车确认”，司机收到《验收单二维码》开始起运；</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >⑥司机到达卸货地点后，联系收货人收货，并按苗仓“地被直采中心”《承运商服务标准》中的相关要求监督验收过程，并对验收有异议的苗木拍照上传到平台，卸车完成后司机将《验收单二维码》交采购方收货人扫码签收。</span
      >
    </p>
    <ol start="22" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第八条：运输任务执行中的注意事项</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.1起运前注意事项</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.1.1运力匹配</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >按平台跟单员的派单，匹配相应车辆完成相关运输任务</span
      >
    </p>
    <ol start="25" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.1.2到达取货点</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方必须按照所承诺的时间到达装货地点，不得迟到延迟装货</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >司机接到装车路线后按时间依次到每个装车点进行装车。按预设时间到达现场后30分钟内未能装车，司机需向跟单员反馈报备。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >复核苗木：苗木装车前应与苗圃联系人核对货物信息无误后再装车</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">准备卷尺、遮荫网、苫布</span>
    </p>
    <ol start="26" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.2运输途中注意事项</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >车辆在运输途中，遇到下雨天，导致苗木泥土吸水变重，乙方不得私自采取中途弃货减重的方式躲避交通部门的检查。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >如路途较远或途中因其它原因停留时间较长，应在服务区或加油站适当喷水，保持潮湿。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方运输途中，不得半途找任何理由再拼货运输，否则因此造成的一切损失，由乙方负责。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方运输途中，不得中途换车、过车的，因私自换车过车产生的一切情损失由乙方全额承担。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方司机运输过程中，应该提前规划好路线，避开某些路段的限行，因乙方规划的原因导致的一切交通部门的处罚，由乙方自行承担与甲方无关。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方不得利用承运车辆进行走私等违法犯罪活动,否则,由此造成的一切经济损失及法律责任均由乙方承担。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >货物运输过程中若发生意外交通事故，无论是否导致货物损坏，乙方在启动交通事故救急预案的同时，并随时通报事故处理情况给甲方，甲方仅协助乙方向保险公司索赔。</span
      >
    </p>
    <ol start="27" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.3到达现场后的注意事项</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.3.1卸车前 </span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >苗木到场后，应做到随到随卸，不能及时卸车的，应将车停放在阴凉处，并用遮荫网或苫布盖好；
      </span>
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >30分钟内未能及时卸车的需向跟单员反馈报备。
      </span>
    </p>
    <ol start="29" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.3.2卸车中 </span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >卸车过程监督工人卸车是否有乱扔乱放现象。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">卸货过程拍照及视频上传到平台</span>
    </p>
    <ol start="30" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>8.3.3卸车后</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">平台收货“二维码”交采购方扫码签收</span>
    </p>
    <ol start="31" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第九条：双方责任</span>
      </li>
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>9.1甲方责任</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >苗仓“地被直采中心”平台的日常维护、技术支持，保证平台的正常运作</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >为乙方提供平台司机端操作流程及平台规则培训</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >负责派发运输订单给乙方，并对订单所需车辆型号、起货点(有多点起货的需全部说明）、到达起货点时间、起货地点的联系人电话、运输物资的重量、卸货地点到达时间、卸货地点及联系人电话}。并对订单需求信息负责</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">按本协议要求支付运费</span>
    </p>
    <ol start="33" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>9.2乙方责任</span>
      </li>
    </ol>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >接受并组织相关人员接受甲方的培训及考核</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >为平台派发的运输订单匹配相应的车辆完成从装货、运输、卸货等一条龙运输服务</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >严格按本协议中的第七条执行相关运输各个过程中的作业要求</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方为乙方所派遣司机及相关人员的一切行为承担完全的经济及法律责任，包括但不限于乙方派遣人员行为所导致的交通事故、货物残损灭失、提供的虚假证件、虚假信息或其他弄虚作假行为、掉包、偷窃、非法扣押货物、其他财产损失等。由乙方派遣人员的行为导致甲方利益受损的，乙方需按损失金额直接向甲方作出赔偿，并负责赔偿甲方因此而承担的所有直接损失（包括但不限于交通事故所引起的赔偿、由于应诉及诉讼所产生律师费、公证费、法院或仲裁等的相关费用）。</span
      >
    </p>
    <p style="text-indent: 6.5pt; line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >运输过程中对货物造成丢失、损坏乙方应按照实际货值赔偿，因此而造成的甲方及甲方客户的经济损失，由乙方承担。</span
      >
    </p>
    <ol start="34" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          
          line-height: 24pt;
          
          font-family: 黑体;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span>第十条：处罚措施</span>
      </li>
    </ol>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >10.1乙方违反本合同第七条相关要求中的任何一条，每违反其中的一条一次的，除乙方必须承担因违规产生的一切经济损失外，扣除履约保证金500元/条/次，以此类推，下次承接运输任务前，补足履约保证金，开台才开放其接单资格。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >10.2任何一条个月内违反三次的，除乙方必须承担因违规产生的一切经济损失外，甲方有权清退乙方，乙方不得有异议。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 12pt; font-weight: bold"
        >第十一条：</span
      ><span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >反商业贿赂条款</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.1商家应当严格遵守法律法规有关禁止商业贿赂行为规定，坚决拒绝商业贿赂、行贿及其他不正当商业行为的馈赠，不得通过平台及/或其关联公司的员工谋取不正当利益。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.2若甲方平台及/或其关联公司员工要求乙方给予其任何形式的不正当利益，乙方应及时投诉，并提供相关证据给甲方。甲方相关人员查实后作出处理，并为乙方保密。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.3乙方向甲方承诺，就甲方进行的任何合规审计和调查合作提供甲方要求的全部合理信息和协助。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.4若乙方违反本反商业贿赂条款的任何约定，甲方有权立即解除本协议，停止与乙方在本协议或任何其他合同项下的部分或全部合作，并赔偿甲方因此遭受的全部损失。违约条款与本条约定不一致的，以本条约定为准。情节严重、造成重大经济损失的，甲方将依法移交有权机关处理。</span
      >
    </p>
    <p style="line-height: 23pt">
      <span style="font-family: 黑体; font-size: 12pt; font-weight: bold"
        >第十二条：合同的期限</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体">{{ year }}年</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">月</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">日起至{{ year }}年</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">月</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">日止。</span>
    </p>
    <p style="line-height: 23pt">
      <span style="font-family: 黑体; font-size: 12pt; font-weight: bold"
        >第十三条：合同的解除和终止</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >13.1如因不可抗力因素导致本合同无法履行的，乙方应当及时告知甲方，并采取积极措施防止损失扩大，与甲方保持沟通协商。乙方履行上述义务后不可抗力情况仍在持续的，双方均可请求解除合同。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >13.2按上述第五条，乙方重大违规达三次的，符合甲方清退标准的。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >13.3除上述条款外，任何一方解除合同，需提前一个月以书面形式告知对方。</span
      >
    </p>
    <p style="line-height: 23pt">
      <span style="font-family: 黑体; font-size: 12pt; font-weight: bold"
        >第十四条：违约责任</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.1无论任何情况下甲乙双方发生争议（包括但不仅限于货损赔偿或结算等），乙方不得以任何理由方式扣压、留置所承运货物，乙方如强行扣压货物不按合同条款规定配送，视为非法侵占，甲方有权报案追究乙方侵占法律责任，同时乙方必须按实际货值向甲方支付一切经济损失。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.2乙方应当全面履行本合同约定，如违反上述任意条款即构成违约，甲方有权按上述条款追究乙方违约责任及所产生的直接损失，和诉讼费用（包括但不限于：诉讼费、律师费、保全费、鉴定费、公证费等费用）均由乙方承担。</span
      >
    </p>
    <p style="line-height: 23pt">
      <span style="font-family: 黑体; font-size: 12pt; font-weight: bold"
        >第十五条：其它</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >15.1与本合同有关的任何争议，双方友好协商解决，协商不成，则向合同签订地有管辖权的人民法院提起诉讼。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >15.2保密条款：合同双方对本合同的运价有保密义务，不得擅自泄漏披露，否则违约方应赔偿因此而遭受的一切经济损失。即使合同终止上述保密协议持续有效。</span
      >
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >15.3本合同自签订之日起生效，本合同一式二份，双方各执一份。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十六条：合同附件</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">相关标准：《物流承运商服务标准》</span>
    </p>
    <p style="text-indent: 10.5pt; line-height: 20pt">
      <span style="font-family: 宋体; -aw-import: ignore">&nbsp;</span>
    </p>
    <p style="text-indent: 24pt; line-height: 20pt">
      <span style="font-family: 华文细黑; font-size: 12pt; -aw-import: ignore"
        >&nbsp;</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体">甲方：中山苗仓供应链管理有限公司</span
      ><span style="margin-left: 12pt; font-family: 宋体">乙方：</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; -aw-import: ignore">&nbsp;</span>
    </p>
    <p style="10.5pt; line-height: 20pt">
      <span style="font-family: 宋体">授权代表：</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      ><span style="font-family: 宋体">授权代表：</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; -aw-import: ignore">&nbsp;</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体">签约地点：中山市小榄镇海港城写字楼</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; -aw-import: ignore">&nbsp;</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体">签订日期：{{ year }}年</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">月</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">日</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">签订日期：{{ year }}年</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">月</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;</span
      ><span style="font-family: 宋体">日</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "DriverCooperation",

  mounted() {
    window.scrollTo(0, 0)
  },

  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>

<style>
body {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
h1,
h2,
h3,
p {
  margin: 0pt;
}
table {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
h1 {
  margin-top: 17pt;
  margin-bottom: 16.5pt;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 240%;
  font-family: 等线;
  font-size: 22pt;
  font-weight: bold;
  color: #000000;
}
h2 {
  margin-top: 13pt;
  margin-bottom: 13pt;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 172%;
  font-family: Arial;
  font-size: 16pt;
  font-weight: bold;
  color: #000000;
}
h3 {
  margin-top: 13pt;
  margin-bottom: 13pt;
  text-align: justify;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 172%;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 16pt;
  font-weight: bold;
  color: #000000;
}
.CommentText {
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.Footer {
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.Header {
  text-align: justify;
  line-height: normal;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.NormalWeb {
  margin: 5pt 0pt;
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 12pt;
}
.TOC1 {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.TOC2 {
  margin-left: 21pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.TOC3 {
  margin-left: 42pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.WPSOffice1 {
  font-size: 10pt;
}
.WPSOffice2 {
  font-size: 10pt;
}
.WPSOffice3 {
  font-size: 10pt;
}
span.\31 Char {
  font-size: 22pt;
  font-weight: bold;
}
span.\32 Char {
  font-family: Arial;
  font-size: 16pt;
  font-weight: bold;
}
.page {
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  word-break: break-all;
}
</style>
